import { Box, Grid, Paper } from '@mui/material';
import React, { useState } from 'react';
import CalculateSavingFormWidget from './calculate-saving-form-widget/calculate-saving-form-widget';

import DashboardWidget from './dashboard-widget/dashboard-widget';


const INVOICES_ENTERED_IN_BFLOWS = parseFloat(process.env.REACT_APP_INVOICES_ENTERED_IN_BFLOWS);
const SETTLED = parseFloat(process.env.REACT_APP_SETTLED);
const SETTLEMENT_FEE = parseFloat(process.env.REACT_APP_SETTLEMENT_FEE);
const SETTLED_WITH_MEMENTO = parseFloat(process.env.REACT_APP_SETTLED_WITH_MEMENTO);
const INSOLVENCY_REDUCTION_WITH_MEMENTO = parseFloat(process.env.REACT_APP_INSOLVENCY_REDUCTION_WITH_MEMENTO);
const SUBSCRIPTION = parseFloat(process.env.REACT_APP_SUBSCRIPTION);
const CALCULATION_PARAMETERS_1 = parseFloat(process.env.REACT_APP_CALCULATION_PARAMETERS_1);
const CALCULATION_PARAMETERS_2 = parseFloat(process.env.REACT_APP_CALCULATION_PARAMETERS_2);

function Widget(props) {
	const [response, setResponse] = useState();

	const calculate = (data) => {
    
    const response = {};

    const invoicesEnteredBflows = (INVOICES_ENTERED_IN_BFLOWS) * data.revenue;
    const settled = (SETTLED) * invoicesEnteredBflows;
    const settlementFee = (SETTLEMENT_FEE) * settled;

    response.monthLossesInsolvencies = (((CALCULATION_PARAMETERS_1) * data.revenue) * (data.insolvencyPercentage * 10)) + 1;
    response.monthLossesDelayedPayments = (Math.ceil(((CALCULATION_PARAMETERS_2) * data.revenue) * data.averagePaymentDays) * 1.2) - 1;
    response.monthlyTotal1 = response.monthLossesInsolvencies + response.monthLossesDelayedPayments;
    response.annualTotalLossesDelays = response.monthlyTotal1 * 12;
    response.totalInterestExpenses = settled * data.invoiceAdvanceCost;
    response.totalCostsPlusLosses = response.totalInterestExpenses + response.annualTotalLossesDelays;
    response.monthReductionLossesInsolvencies = response.monthLossesInsolvencies * (INSOLVENCY_REDUCTION_WITH_MEMENTO);
    response.monthReductionLossesDelayedPayments = response.monthLossesDelayedPayments - (response.monthLossesDelayedPayments * (1 - (SETTLED_WITH_MEMENTO)));
    response.invoiceAdvanceInterest = response.totalInterestExpenses / 12;
    response.monthlyTotal2 = response.monthReductionLossesInsolvencies + response.monthReductionLossesDelayedPayments;
    response.annualTotalM = response.monthlyTotal2 * 12;
    response.annualTotalBf = response.annualTotalLossesDelays * ((INVOICES_ENTERED_IN_BFLOWS) * (SETTLED));
    response.annualTotal = response.totalInterestExpenses;
    response.generatedSavings = response.annualTotalM + response.annualTotalBf + response.annualTotal;
    response.savingsMinusServiceCosts = response.generatedSavings - settlementFee - (SUBSCRIPTION);
    response.previousYearProfits = data.revenue * data.previousYearProfit;
    response.profitGrowth = response.savingsMinusServiceCosts / response.previousYearProfits;
    response.totalProfitsMementoBflows = response.previousYearProfits + response.savingsMinusServiceCosts;
    response.profitPercentage = response.totalProfitsMementoBflows / data.revenue;

		setResponse({
      generatedSavings: response.generatedSavings,
      totalProfitsMementoBflows: response.totalProfitsMementoBflows,
      profitPercentage: response.profitPercentage-data.previousYearProfit
    });
	}

	return (
    <Paper elevation={1} sx={{
      border: `2px solid ${props.primaryColor}`,
      width: '99%',
      minWidth: { sm:800 },
      height: '99%',
      borderRadius: '5px',
    }}>
      <Grid container height="100%">
        <Grid item xs={12} sm={6} 
          display="flex" 
          justifyContent="center" 
          alignItems="center"
          sx={{ bgcolor: props.secondaryColor }}>
            <CalculateSavingFormWidget calculate={calculate} sliderColor={props.primaryColor} labelColor={props.thirdColor}/>
        </Grid>
        <Grid item xs={12} sm={6} 
          display="flex" 
          justifyContent="center" 
          alignItems="center"
          sx={{ bgcolor: props.primaryColor }}>
          <DashboardWidget data={response} color={props.secondaryColor}/>
        </Grid>
      </Grid>
    </Paper>
	)
}

export default Widget;