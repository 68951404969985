import { Box, Container, CssBaseline, Grid, Typography } from '@mui/material';
import Header from '../structure/header/header';
import Footer from '../structure/footer/footer';
import CalculateSavingForm from '../components/calculate-saving-form/calculate-saving-form';
import React, { useState } from 'react';
import Dashboard from '../components/dashboard/dashboard';
import { calculateSavings } from '../services/saving-service';


function Home() {
	const [response, setResponse] = useState();

	const calculate = (data) => {
		calculateSavings(data, calculateSavingsCallback, onError);
	}

	const calculateSavingsCallback = (response) => {
		setResponse(response);
	}

	const onError = (response) => {
		console.log(response);
	}
	
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh',
			}}
		>
			<CssBaseline />
			<Box>
				<Header/>
				<Box
					sx={{
						bgcolor: 'background.paper',
						m: "6% auto 8%",
						width: "100%"
					}}
				>
					<Container maxWidth="md">
						<Typography
							textAlign="center"
							variant='h4'
							fontWeight={300}
							color="primary">
								Incassa prima le tue fatture e paga con puntualità i tuoi fornitori</Typography>
					</Container>
					<Typography id="calculate-form" textAlign="center" variant='body1' mt={10}>Compila il form per ottenere una panoramica di quanto potresti risparmiare utilizzando il servizio.</Typography>

					<Grid container 
						bgcolor="#FAFAFA" 
						px={8}
						pb={4} 
						mt={3} 
						columnSpacing={10}
						rowSpacing={6}>
							<Grid item md={12} lg={6} sx={{ height: "100%" }}>
								<CalculateSavingForm calculate={calculate}/>
							</Grid>
							{!response && <Grid item md={12} lg={6} display="flex" alignItems={'center'} justifyContent={"center"}>
								<img src="https://static.wixstatic.com/media/9f529c_6f78d60d5361445ebdec2cf704898472~mv2.png/v1/fill/w_1290,h_1058,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/algo_Tavola%20disegno%201.png" style={{maxWidth: '100%', height: 'auto', objectFit: 'cover'}} alt="algo_Tavola disegno 1.png" fetchpriority="high"/>
							</Grid>}
							{response && <Grid item xs={12} md={12} lg={6} 
								sx={{
									display: {
										xs: 'flex',
										lg: 'block'
									},
									alignItems: 'center',
									justifyContent: 'center',
								}}>
								<Dashboard data={response}/>
							</Grid>}
					</Grid>
				</Box>
			</Box>
			<Footer />
		</Box>
	  );
}

export default Home;