import React from 'react';
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

function DashboardWidget({data, color}) {

  function formatNumberWithSeparator(number) {
    const formattedNumber = number?.toLocaleString('it-IT', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  }

  function addSignToNumber(number) {
    const regex = /^(-)+\d+(\,\d+)?$/;
  
    if (regex.test(number)) {
      return number;
    }
  
    return "+" + number;
  }
  
  
  return (
    <Stack 
      py={8} 
      px={5}
      spacing={6}
      alignItems="center" 
      color={color}>
      <Box textAlign="center">
        <Typography textTransform="uppercase">Risparmio generato</Typography>
        <Typography variant="h4" mt={1}>{formatNumberWithSeparator(data?.generatedSavings)} &euro;</Typography>
      </Box>
      <Divider sx={{borderColor: color, width: "100%"}}/>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography textTransform="uppercase" variant="caption">Percentuale utile</Typography>
            <Typography variant="body1">{addSignToNumber(formatNumberWithSeparator((data?.profitPercentage*100)))} %</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography textTransform="uppercase" variant="caption">Totale utili</Typography>
            <Typography variant="body1">{formatNumberWithSeparator(data?.totalProfitsMementoBflows)} &euro;</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default DashboardWidget;
