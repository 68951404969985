import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormSlider from '../../../commons/form-slider';


function CalculateSavingFormWidget({calculate, sliderColor, labelColor}) {

  const schema = yup.object().shape({
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { 
      errors,
      isValid 
    },
    getValues,
    watch
  } = methods;

  const sliderValues = watch([
    'revenue',
    'invoiceAdvanceCost',
    'insolvencyPercentage',
    'previousYearProfit',
    'averagePaymentDays'
  ]);

  useEffect(() => {

    let data = getValues();
  
    data.invoiceAdvanceCost = data.invoiceAdvanceCost / 100;
    data.previousYearProfit = data.previousYearProfit / 100;
  
    calculate(data);
  }, []);

  useEffect(() => {

    if (!isValid) {
      return;
    }

    let data = getValues();
  
    data.invoiceAdvanceCost = data.invoiceAdvanceCost / 100;
    data.previousYearProfit = data.previousYearProfit / 100;
  
    calculate(data);
    
  }, [...sliderValues]);

  /* const onSubmit = (values) => {

    if(!isValid) {
      console.log(errors);
      return;
    }

    let request = values;
    
    request.invoiceAdvanceCost = values.invoiceAdvanceCost/100;
    request.previousYearProfit = values.previousYearProfit/100;

    calculate(request);
  }; */
  
  return (
    <FormProvider {...methods}>
      <Box component="form" px={6} py={3}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <FormSlider
              label="Fatturato"
              name="revenue"
              description="Fatturato dell'azienda"
              sliderColor={sliderColor}
              labelColor={labelColor}
              step={100000}
              min={100000}
              max={100000000}
              defaultValue={500000}
              symbol="&euro;"
              error={errors['revenue']}
              required/>
          </Grid>
          <Grid item xs={12}>
            <FormSlider
              label="Costo anticipato fatture" 
              name="invoiceAdvanceCost"
              description="Percentuale applicata alle fatture emesse che viene pagata come anticipo per ottenere un finanziamento immediato"
              sliderColor={sliderColor}
              labelColor={labelColor}
              min={1}
              max={20}
              symbol="%"
              defaultValue={5}
              error={errors['invoiceAdvanceCost']}
              required/>
          </Grid>
          <Grid item xs={12}>
            <FormSlider
              label="Percentuale insolvenze" 
              name="insolvencyPercentage"
              description="Proporzione delle fatture che non sono state pagate entro il termine stabilito"
              sliderColor={sliderColor}
              labelColor={labelColor}
              min={1}
              max={20}
              symbol="%"
              defaultValue={2}
              error={errors['insolvencyPercentage']}
              required/>
          </Grid>
          <Grid item xs={12}>
            <FormSlider
              label="Utile anno precedente" 
              name="previousYearProfit"
              description="Profitto generato nell'anno precedente"
              sliderColor={sliderColor}
              labelColor={labelColor}
              min={1}
              max={100}
              symbol="%"
              defaultValue={5}
              error={errors['previousYearProfit']}
              required/>
          </Grid>
          <Grid item xs={12}>
            <FormSlider
              label="Giorni medi di pagamento" 
              name="averagePaymentDays"
              description="Numero medio di giorni richiesti per il pagamento delle fatture da parte dei clienti"
              sliderColor={sliderColor}
              labelColor={labelColor}
              min={0}
              max={200}
              symbol="gg"
              defaultValue={90}
              error={errors['averagePaymentDays']}
              required/>
          </Grid>
        </Grid>
      </Box>
  </FormProvider>);
}

export default CalculateSavingFormWidget;
