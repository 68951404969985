import React from 'react';
import Widget from '../components/widget/widget';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

function formatRGBValue(value, fallbackColor) {
  const hexRegex = /^[0-9A-Fa-f]{6}$/;

  if (hexRegex.test(value)) {
    return `#${value.toUpperCase()}`;
  }

  return fallbackColor;
}

function WidgetPage() {
  const location = useLocation();
  const searchParams  = new URLSearchParams(location.search);
  const primaryColor = searchParams.get('primaryColor');
  const secondaryColor = searchParams.get('secondaryColor');
  const thirdColor = searchParams.get('thirdColor');

  const pageStyle = {
    height: '100vh',
    width: '100vw',
  };

	return (
    <Box sx={pageStyle}>
      <Widget primaryColor={formatRGBValue(primaryColor, "#49E0C3")} secondaryColor={formatRGBValue(secondaryColor, "#ffffff")} thirdColor={formatRGBValue(thirdColor, '#000000')}/>
    </Box>
	)
}

export default WidgetPage;