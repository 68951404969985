import React from 'react';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import { InputLabel, Stack, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { Controller, useFormContext } from 'react-hook-form';

/* const TextOnlyTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "transparent"
  }
})(Tooltip); */


const CustomSlider = styled(Slider)(({ theme }) => ({
  color: "#000000",
  '& .MuiSlider-valueLabel': {
    fontSize: 13,
    fontWeight: 'normal',
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
}));

const FormSlider = ({ label, name, symbol, defaultValue, description, sliderColor, labelColor, ...otherProps }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();


  const formatValueLabel = (value) => {

    let formattedValue = value;

    if (value >= 100000) {
      formattedValue = `${(value / 1000000).toFixed(2)}M`;
    }

    return `${formattedValue} ${symbol}`;
  };
  

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          <Stack direction="row" justifyContent="space-between" mb={1}>
            <InputLabel 
              sx={{
                color: labelColor ?? '#000000', 
                display: "flex",
                alignItems:"center"
              }} shrink>
                {label} &nbsp;
                <Tooltip title={description}>
                  <InfoIcon sx={{fontSize: '18px', color: labelColor ?? '#cecece'}}/>
                </Tooltip>
            </InputLabel>
            <Typography color={sliderColor?? "#49E0C3"} variant='body2' fontWeight="bold">{formatValueLabel(field.value)}</Typography>
          </Stack>
          <CustomSlider 
            size="small" 
            step={otherProps.step}
            sx={{color: sliderColor ?? '#49E0C3'}}
            {...field} 
            {...otherProps} />
        </>
      )}
    />
  );
};

export default FormSlider;