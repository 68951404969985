import { Box, Link, Stack, Typography } from '@mui/material';
import './style.scss';

function Footer() {

  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        // ml: "240px",
        backgroundColor: (theme) => theme.palette.footer.main,
        color: (theme) => theme.palette.text.secondary,
        zIndex: (theme) => theme.zIndex.drawer+2,
      }}
    >
      <Stack className='social-container' direction='row' spacing={1.5} justifyContent="center">
        <Link>
          <Stack className='fa-container' justifyContent='center' alignItems='center'>
            <i class="fa fa-facebook"></i>
          </Stack>
        </Link>
        <Link>
          <Stack className='fa-container' justifyContent='center' alignItems='center'>
            <i class="fa fa-twitter"></i>
          </Stack>
        </Link>
        <Link>
          <Stack className='fa-container' justifyContent='center' alignItems='center'>
            <i class="fa fa-instagram"></i>
          </Stack>
        </Link>
        <Link>
          <Stack className='fa-container' justifyContent='center' alignItems='center'>
            <i class="fa fa-linkedin"></i>
          </Stack>
        </Link>
      </Stack>
      <Stack direction='row' spacing={1} mt={3} justifyContent="center">
        <Typography variant="caption">Via Angioy, 34 &nbsp;Cagliari</Typography>
        <Typography variant="caption">|</Typography>
        <Typography variant="caption">mail@</Typography>
      </Stack>
      <Stack alignItems='center' mt={1}>
        <Typography variant="caption">&copy; {currentYear} Bflows Srl</Typography>
      </Stack>
    </Box>
  );
}

export default Footer;
