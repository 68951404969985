import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const CircularButton = styled(Button)(({ theme, color }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  fontWeight: "bold",
  color: theme.palette.text.secondary,
  backgroundColor: color || theme.palette.primary.main,
  border: `2px solid ${color ? theme.palette[color]?.main : theme.palette.primary.main}`,
  padding: '8px 20px',
  borderRadius: '50px',
  lineHeight: 1.5,
  '&:hover': {
    backgroundColor: 'transparent',
    color: color ? theme.palette[color]?.main : theme.palette.primary.main,
    boxShadow: 'none',
  },

  // Variante "contained"
  '&.MuiButton-contained': {
    color: theme.palette.text.secondary,
    backgroundColor: color || theme.palette.primary.main,
    border: `2px solid ${color ? theme.palette[color]?.main : theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: 'transparent',
      color: color ? theme.palette[color]?.main : theme.palette.primary.main,
      boxShadow: 'none',
    },
  },

  // Variante "outlined"
  '&.MuiButton-outlined': {
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    borderColor: theme.palette.text.secondary,
    boxShadow: 'none',
    '&:hover': {
      border: '2px solid',
      borderColor: theme.palette.text.secondary,
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.primary.main,
    },
  },
}));

export default CircularButton;