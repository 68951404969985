import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createTheme,
  ThemeProvider,
} from "@mui/material/styles"

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif;",
  },
  palette: {
      mode: "light",
      primary: {
          main: "#003F60",  //blue
      },
      secondary: {
          main: "#49E0C3",
      },
      success: {
          main: "#007936",
      },
      info: {
          main: "#2F6D94",
      },
      warning: {
          main: "#F26419",
      },
      error: {
          main: "#DD403A",
      },
      background: {
          default: "#FDFFFC",
          paper: "#FDFFFC",
      },
      text: {
          primary: "#000000",
          secondary: "#FDFFFC"
      },
      footer: {
        main: "#0f3749",
      },
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
              },
            },
          },
        },
      },
  },
})


root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();