import './App.css';
import React, { useState } from 'react';

import {
	createBrowserRouter,
	Route,
	RouterProvider,
	Routes,
  } from "react-router-dom";
	
import WidgetPage from './pages/widget-page';
import Home from './pages/home';


const router = createBrowserRouter([
{ path: "*", Component: Root },
]);

export default function App() {
	return <RouterProvider router={router} />;
}

function Root() {
	return (
	  <Routes>
			<Route path="/widget" element={<WidgetPage />} />
			<Route path="/*" element={<WidgetPage />} />
			{/*<Route path="/*" element={<Home />} />*/}
	  </Routes>
	);
}

