import React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { InputLabel } from '@mui/material';

import { Controller, useFormContext } from 'react-hook-form';

const CustomInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      border: '1px solid #2D3843',
    },
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },
}));

const FormTextField = ({ label, name, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field }) => (
        <>
          <InputLabel sx={{ color: (theme) => theme.palette.text.primary }} shrink>
            {label}
          </InputLabel>
          <CustomInput
            {...otherProps}
            {...field}
            error={!!errors[name]}
            helperText={errors[name] ? errors[name].message : ''}
            fullWidth
          />
        </>
      )}
    />
  );
};

export default FormTextField;
